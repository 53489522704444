import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useLazyQuery } from '@apollo/client'

import { useUserSession } from 'hooks'
import redirect from 'lib/universal-redirect'

import GET_WITH_SELLER_DATA from './graphql/GetWithSellerData.graphql'
import { GetWithSellerData } from './graphql/__generated__/GetWithSellerData'
import {
  PaymentMethodStatusEnum,
  PlanEnum,
  TaxNumberCheckStatusEnum,
  SellerStatusEnum
} from '../../__generated__/globalTypes'

/*
 * If not a seller, then redirect to the home page
 * Also checks the seller's status, if inactive redirect to subscription page
 * If has subscription but card is invalid, redirect to inactive page
 *
 */
const withSellerPage = <P extends object>(Component: React.ComponentType<P>) => {
  const ComponentWithSellerPage = (props: P) => {
    const router = useRouter()
    const { isSeller, sessionLoading, sessionAccountId, securityCheck } = useUserSession()

    const [getSellerSubscriptionState] = useLazyQuery<GetWithSellerData>(GET_WITH_SELLER_DATA)

    useEffect(() => {
      ;(async () => {
        if (!router.isReady || sessionLoading) return

        if (!securityCheck({ positiveCondition: Boolean(isSeller), unauthorizedUrl: '/login', returnToIfOK: false }))
          return

        const response = await getSellerSubscriptionState()
        const currentSeller = response.data?.currentSeller
        const stripeSubscription = currentSeller?.commonSubscriptionDetails

        const plan = currentSeller?.plan.id as PlanEnum
        const stripeSubscriptionId = stripeSubscription?.stripeSubscriptionId
        const paymentMethodStatus = stripeSubscription?.paymentMethodStatus
        const marketplaceApprovalStatus = currentSeller?.marketplaceApprovalStatus
        const isOnboardingIncomplete = !(currentSeller?.onboardingState.isOnboardingComplete ?? true)
        const onboardingNextStep = currentSeller?.onboardingState.nextOnboardingStepPath ?? '/'

        // TODO: maybe should remove check on paymentMethodId, as it's a bit inefficient, as required a lookup to Stripe API
        const paymentMethodId = stripeSubscription?.paymentMethodDetails?.paymentMethodId

        if (
          currentSeller?.status != SellerStatusEnum.INACTIVE &&
          currentSeller?.taxNumberCheckStatus === TaxNumberCheckStatusEnum.UNVERIFIED
        ) {
          console.log('Redirecting to tax number verification')
          router.push('/sh/verify-details')
          return
        }

        const allowRedirect = !(
          router.asPath.indexOf('/sh/settings/subscription') !== -1 ||
          router.asPath.indexOf('/sh/subscription/add-payment-method') !== -1 ||
          router.asPath.indexOf('/sh/onboarding') !== -1
        )

        // Redirect if seller is not yet done with onboarding
        if (isOnboardingIncomplete && allowRedirect) {
          router.push(onboardingNextStep)
          return
        }

        if (currentSeller?.status === SellerStatusEnum.INACTIVE && allowRedirect) {
          if ([PlanEnum.PER_ORDER, PlanEnum.RECURRING].includes(plan)) {
            console.log(
              'Redirecting to subscription page',
              marketplaceApprovalStatus,
              paymentMethodStatus,
              paymentMethodId,
              stripeSubscriptionId
            )
            // For invalid cards and expired free trial period, redirect to InactiveAccount Page
            if (
              currentSeller?.status === SellerStatusEnum.INACTIVE ||
              (stripeSubscriptionId !== null &&
                (paymentMethodStatus === PaymentMethodStatusEnum.INVALID || !paymentMethodId))
            ) {
              console.log('Redirecting to inactive account page')
              return redirect({ href: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/sh/settings/subscription/inactive` })
            }

            return redirect({ href: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/sh/settings/subscription` })
          }
        }

        if (!securityCheck({ positiveCondition: Boolean(isSeller), unauthorizedUrl: '/login' })) return
      })()
    }, [getSellerSubscriptionState, router, router.asPath, router.basePath, isSeller, sessionLoading, securityCheck])

    return !sessionAccountId || sessionLoading || !isSeller ? null : <Component {...props} />
  }

  return ComponentWithSellerPage
}

export default withSellerPage
